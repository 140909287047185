import { Button, Card, Divider, Input, Space, Tabs, TabsProps } from "antd";
import { Task, api } from "api";
import { TitleWithActions } from "components/TitleWithActions";
import { useLPForm } from "hooks/useLPForm";
import { Link, useParams } from "react-router-dom";
import { Breadcrumbs } from "components/Breadcrumbs";
import { TaskTabTaskFiles } from "./TaskTabTaskFiles";
import { useAuthContext } from "AuthProvider";
import { FormColInput, MultiColumnForm } from "components/MultiColumnForm";
import { LinkToAuction } from "components/Links/LinkToAuction";
import { TaskState } from "components/State/TaskState";
import { TaskStateUser } from "components/State/TaskStateUser";
import { Crud } from "hooks/useCrud";
import { renderTextWithLinks } from "pages/Auction/AuctionEditPage";
import { renderDate, renderDateTime } from "utils/renderer";
import { LinkToProposal } from "components/Links/LinkToProposal";
import { LinkToUser } from "components/Links/LinkToUser";

export const TaskEditPage = () => {
  const { taskId: id } = useParams<{ taskId: string }>();
  const { hasAdminRole, hasUserRole } = useAuthContext();

  const {
    data: respData,
    showForm,
    form,
    save,
    field,
    viewOrEdit,
    actions,
    toFormData,
  } = useLPForm<Task>({
    id,
    isEdit: !id,
    endpoint: api.task,
    onAfterCreateNavigateTo: (data) => `/platform/tasks/${data.id}`,
    onAfterDeleteNavigateTo: (data) => "/platform/tasks",
  });

  const { save: saveTask } = Crud.task.useSave();

  const submitTaskByUser = () => {
    const fd = new FormData();
    fd.set("id", id as string);
    fd.set("state", "submitted");
    saveTask(fd);
  };

  const data = respData || {};

  const handleOnFinish = (values: any) => {
    save(toFormData(values));
  };

  // TaskTabTaskFiles
  const showFilesSection =
    hasUserRole ||
    ["submitted", "approved", "canceled", "completed"].includes(data?.state);

  const tabs: TabsProps["items"] = [
    {
      key: "files",
      label: `Files`,
      children: data?.id ? (
        <TaskTabTaskFiles taskId={data?.id} state={data?.state} />
      ) : null,
      disabled: !data?.id,
    },
  ];

  const taskActions = hasAdminRole ? (
    <Space>
      <TaskState.Selector id={id} state={data?.state} />
    </Space>
  ) : (
    data?.state === "active" && (
      <Button
        onClick={() => {
          submitTaskByUser();
        }}
      >
        Submit
      </Button>
    )
  );

  return (
    <>
      <Breadcrumbs
        items={[
          {
            title: "Tasks",
            to: "/platform/tasks",
          },
          {
            title: "Task",
          },
        ]}
      />

      <Card style={{ border: "none", backgroundColor: "white" }}>
        {hasUserRole && <TaskStateUser.Steps state={data?.state} />}
        <TitleWithActions
          title={id ? <>{data?.name}</> : "Create tasks"}
          actions={taskActions}
        />
        {showForm && (
          <MultiColumnForm
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            layout="horizontal"
            style={{ maxWidth: 852 }}
            labelWrap={true}
            initialValues={data}
            form={form}
            onFinish={handleOnFinish}
            autoComplete="off"
          >
            {hasAdminRole && (
              <FormColInput label="Id" name={field("id")}>
                {data.id}
              </FormColInput>
            )}
            {/* <FormColInput label="Name">{data?.auction?.name}</FormColInput> */}
            {hasAdminRole && (
              <FormColInput label="S3 Folder">
                {hasAdminRole ? (
                  <Link to={data?.s3_folder_url}>{data?.s3_folder}</Link>
                ) : (
                  data?.s3_folder
                )}
              </FormColInput>
            )}
            {data?.auction && (
              <FormColInput label="Document link">
                <a
                  style={{ wordWrap: "break-word" }}
                  href={data.link_to_document}
                  target="_blank"
                  rel="noreferrer"
                >
                  {data.link_to_document?.substring(0, 120)}
                </a>
              </FormColInput>
            )}

            {data?.auction && (
              <FormColInput label="Auction">
                <LinkToAuction {...data?.auction} />
              </FormColInput>
            )}
            {data?.proposal && (
              <FormColInput label="Proposal">
                <LinkToProposal {...data?.proposal} />
              </FormColInput>
            )}
            <FormColInput label="Start date">
              {renderDate(data?.start_date)}
            </FormColInput>
            <FormColInput label="Commitment deadline">
              {renderDate(data?.deadline_date)}
            </FormColInput>
            <FormColInput label="Video quantity">
              {data?.auction?.quantity || data?.proposal?.quantity}
            </FormColInput>
            {data?.auction && (
              <FormColInput label="Max size">
                {data?.auction?.max_size &&
                  data?.auction?.max_size + " Megabytes"}
              </FormColInput>
            )}
            {data?.auction?.description && (
              <FormColInput label="Description">
                {renderTextWithLinks(data?.auction?.description)}
              </FormColInput>
            )}
            {hasAdminRole && (
              <FormColInput label="Assignee">
                <LinkToUser {...data?.author} />
              </FormColInput>
            )}
            {data?.proposal?.description && (
              <FormColInput label="Description">
                {renderTextWithLinks(data?.proposal?.description)}
              </FormColInput>
            )}
            {/* <Form.Item label="Auction">{data.auction?.name}</Form.Item> */}
          </MultiColumnForm>
        )}
        {id && showFilesSection && (
          <Tabs items={tabs} defaultActiveKey={undefined} />
        )}
      </Card>
    </>
  );
};
