import { Card } from "antd";
import { Proposal, api } from "api";
import { ButtonCreateNavigate } from "components/ButtonCreateNavigate";
import { DataTable } from "components/DataTable/DataTable";
import { ProposalState } from "components/State/ProposalState";
import { TitleWithActions } from "components/TitleWithActions";
import { useNavigate } from "react-router-dom";
import { renderDateTime, renderPrice } from "utils/renderer";

export const MyProposalListPage = () => {
  const navigate = useNavigate();

  const handleEdit = (id: string) => {
    navigate(`/platform/my-proposals/${id}`);
  };

  return (
    <Card>
      <TitleWithActions
        title="My Proposals"
        actions={
          <ButtonCreateNavigate link="/platform/my-proposals/create">
            Proposal
          </ButtonCreateNavigate>
        }
      />
      <DataTable<Proposal>
        endpoint={api.myProposal}
        columns={columns as any}
        tableId={"MyProposalsListPage"}
        onEdit={(record) => {
          handleEdit(record.id);
        }}
      />
    </Card>
  );
};

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    sortIndex: "name",
    sorter: true,
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    sorter: true,
  },
  {
    title: "Proposed budget total",
    dataIndex: "proposed_budget",
    key: "proposed_budget",
    sorter: true,
    render: (val: any) => renderPrice(val),
  },
  {
    title: "Currency",
    dataIndex: "currency",
    key: "currency",
    sorter: true,
  },
  {
    title: "Submitted at",
    dataIndex: "submitted_at",
    key: "submitted_at",
    sorter: true,
    render: renderDateTime,
  },
  {
    title: "Admin comment",
    dataIndex: "admin_comment",
    key: "admin_comment",
    sorter: true,
  },
  {
    title: "Status",
    dataIndex: "state",
    key: "state",
    sorter: true,
    render: ProposalState.render,
  },
];
