import { App, Form, Input, Modal } from "antd";
import { useForm } from "antd/es/form/Form";
import { HttpErrorType, ResponseBase, api } from "api";
import { AntDFieldError } from "hooks/useLPForm";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";

export interface UpdatePasswordModalProps {
  id: string;
  open: boolean;
  onClose: () => void;
}

export const UpdatePasswordModal = ({
  id,
  open,
  onClose,
}: UpdatePasswordModalProps) => {
  const { message } = App.useApp();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const queryClient = useQueryClient();
  const [form] = useForm();

  const resolveValidationServerErrors = (
    form: any,
    error: HttpErrorType
  ): void => {
    const data = error?.response?.data as any;
    // if (!data || data.message !== "Validation error") return;
    const errorData = data.data as { [key: string]: string[] };
    const antDFormErrors: AntDFieldError[] = [];
    for (const key in errorData) {
      if (typeof errorData[key] === "object" && errorData[key].length) {
        antDFormErrors.push({
          name: key,
          errors: [errorData[key][0] as any as string],
        });
        continue;
      }
    }
    const allAntDFormFields: AntDFieldError[] = Object.keys(
      form.getFieldsValue()
    ).map((field) => ({
      name: field,
      errors: [],
    }));
    /*
     * Merging all fields with the recent errors. This is needed to clear previous errors that are not present in the recent response
     */
    const fieldErrors = [...allAntDFormFields, ...antDFormErrors];
    // console.log("!!!!!!!! fieldErrors", fieldErrors);
    form.setFields(fieldErrors);
  };

  const updateMutation = useMutation<ResponseBase<any>, any, FormData>(
    (data) => {
      return api.me.updateMyPassword(data);
    },
    {
      onMutate: () => {
        setIsSubmitting(true);
      },
      onError: (e) => {
        resolveValidationServerErrors(form, e);
        setIsSubmitting(false);
        const errorMsg = [e.response.data.message];
        if (e?.response?.data?.data) {
          for (const [key, value] of Object.entries(e?.response?.data?.data)) {
            errorMsg.push(...(value as []));
          }
        }

        message.error(
          errorMsg.map((msg) => (
            <>
              {msg}
              <br />
            </>
          ))
        );
      },
      onSuccess: (response) => {
        queryClient.invalidateQueries(api.me.key);
        message.success(response.message);
        setIsSubmitting(false);
        onClose();
      },
    }
  );

  const onFinish = (values: any) => {
    const fd = new FormData();
    fd.append("o_password", values.o_password);
    fd.append("password", values.password);
    fd.append("c_password", values.c_password);
    updateMutation.mutate(fd);
  };

  return (
    <Modal
      open={open}
      title="Update password"
      onCancel={() => onClose()}
      okText="Update"
      okButtonProps={{ loading: isSubmitting }}
      onOk={() => form.submit()}
    >
      <Form
        name="basic"
        layout="vertical"
        form={form}
        wrapperCol={{ span: 24 }}
        style={{ maxWidth: 600 }}
        initialValues={{}}
        onFinish={onFinish}
        requiredMark={false}
        autoComplete="off"
      >
        <Form.Item
          label="Current Password"
          name="o_password"
          rules={[{ required: true, message: "Current password required" }]}
        >
          <Input.Password autoComplete={"off"} />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: "New password required" }]}
        >
          <Input.Password autoComplete={"off"} />
        </Form.Item>
        <Form.Item
          label="Repeat password"
          name="c_password"
          dependencies={["password"]}
          rules={[
            { required: true, message: "Password required" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The new password and confirmation does not match!")
                );
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
      </Form>
    </Modal>
  );
};
