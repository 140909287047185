import { useAuthContext } from "AuthProvider";
import { Button, Form, Modal, Space } from "antd";
import { Auction, Bid, User, api } from "api";
import { DataTable } from "components/DataTable/DataTable";
import { TitleWithActions } from "components/TitleWithActions";
import { useState } from "react";
import { renderDateTime, renderPrice, renderQuantity } from "utils/renderer";
import { AuctionBidModal } from "./AuctionBidModal";
import { BidState } from "components/State/BidState";
import { Crud } from "hooks/useCrud";
import { AuctionBidWinConfirmModal } from "./AuctionBidWinConfirmModal";
import { toFormData } from "hooks/useFormData";
import { useQueryClient } from "react-query";
import { LinkToUser } from "components/Links/LinkToUser";

export interface AuctionTabBidsProps {
  auctionId: string;
}

export const AuctionTabBids: React.FC<AuctionTabBidsProps> = ({
  auctionId,
}) => {
  const { user: me } = useAuthContext();
  const auctionResp = Crud.auction.useGet(auctionId);
  const { hasUserRole, hasAdminRole } = useAuthContext();
  const [open, setOpen] = useState(false);
  const auction = auctionResp.data?.data;

  const currency = auction?.currency;
  const queryClient = useQueryClient();

  const [modalConfirmBidOpen, setModalConfirmBidOpen] =
    useState<boolean>(false);
  const [winnerBid, setWinnerBid] = useState<Bid | undefined>(undefined);

  const { save } = Crud.bid.useSave({
    onAfterSuccess: () => {
      queryClient.invalidateQueries([api.bid.key]);
    },
  });

  const disqualifyBid = async (bid: Bid) => {
    const fd = toFormData({ id: bid.id, state: "disqualified" });
    save(fd);
  };

  const columns = [
    {
      title: "Bidder",
      dataIndex: ["user", "name"],
      key: "user_name",
      render: (name: string, record: Bid) => {
        return hasAdminRole ? (
          <LinkToUser id={record.user_id} name={name} />
        ) : (
          name
        );
      },
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (price: string) => renderPrice(price, currency),
    },
    {
      title: "Bid Justification",
      dataIndex: "comment",
      key: "comment",
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: renderDateTime,
    },
    {
      title: "Status",
      dataIndex: "state",
      key: "state",
      render: BidState.render,
    },
    {
      title: "Actions",
      key: "actions",
      width: 1,
      render: (_: any, record: Bid) => {
        if (record.state !== "active" || !hasAdminRole) return null;
        return (
          <Space>
            <Button
              type="primary"
              onClick={() => {
                setModalConfirmBidOpen(true);
                setWinnerBid(record);
              }}
            >
              Win
            </Button>
            <Button
              type="default"
              onClick={() => {
                disqualifyBid(record);
              }}
            >
              Disqualify
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <div>
      <TitleWithActions
        title="Bids"
        actions={
          hasUserRole &&
          auction?.state === "active" && (
            <Button
              type="primary"
              onClick={() => {
                setOpen(true);
              }}
            >
              Bid
            </Button>
          )
        }
      />
      <DataTable
        columns={columns}
        endpoint={api.bid}
        tableId={"AuctionTabBids"}
        extraFilters={{ auction_id: auctionId }}
      />
      <AuctionBidWinConfirmModal
        open={modalConfirmBidOpen}
        bid={winnerBid}
        auction={auction}
        onClose={() => {
          setModalConfirmBidOpen(false);
          setWinnerBid(undefined);
        }}
      />

      <AuctionBidModal
        open={open}
        auctionId={auctionId}
        onClose={() => setOpen(false)}
      />
    </div>
  );
};
