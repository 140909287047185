import { Card, Divider, Form, Input, InputNumber, Select } from "antd";
import { Proposal, api } from "api";
import { TitleWithActions } from "components/TitleWithActions";
import { useLPForm } from "hooks/useLPForm";
import { useParams } from "react-router-dom";
import {
  FormColInput,
  FormColInput2,
  MultiColumnForm,
} from "components/MultiColumnForm";
import { ProposalState } from "components/State/ProposalState";
import { renderDateTime, renderPrice } from "utils/renderer";
import { AjaxSelect } from "components/AjaxSelect";
import TextArea from "antd/es/input/TextArea";
import { LinkToTask } from "components/Links/LinkToTask";
import { renderProposalType } from "pages/MyProposal/MyProposalEditPage";
import { CommentButton } from "components/CommentComponents";

export const ProposalEditPage = () => {
  const { proposalId: id } = useParams<{ proposalId: string }>();

  const {
    data: respData,
    showForm,
    isEditMode,
    form,
    save,
    field,
    viewOrEdit,
    actions,
    toFormData,
  } = useLPForm<Proposal>({
    id,
    isEdit: !id,
    endpoint: api.proposal,
    onAfterCreateNavigateTo: (data) => `/platform/my-proposals/${data.id}`,
    onAfterDeleteNavigateTo: (data) => "/platform/my-proposals",
  });

  const data = respData || {};

  const showField = (field: string) =>
    isEditMode ? true : (data as any)[field];

  return (
    <>
      <Card style={{ border: "none", backgroundColor: "white" }}>
        <TitleWithActions
          title={id ? "Proposal" : "Create Proposal"}
          actions={
            <>
              {(!id || data?.state === "draft") && actions}
              {id && (
                <CommentButton
                  key="CommentButton"
                  modelName="Proposal"
                  modelId={id}
                />
              )}
              {id && <ProposalState.Selector id={id} state={data?.state} />}
            </>
          }
        />
        {showForm && (
          <MultiColumnForm
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            layout="horizontal"
            labelWrap={true}
            style={{ maxWidth: 1200 }}
            initialValues={data}
            form={form}
            onFinish={(data) => {
              save(toFormData(data));
            }}
            autoComplete="off"
          >
            {showField("name") && (
              <FormColInput
                label="Idea topic"
                name={field("name")}
                rules={[{ required: true }]}
              >
                {viewOrEdit(data.name, <Input />)}
              </FormColInput>
            )}
            {showField("type") && (
              <FormColInput
                label="Type"
                rules={[{ required: true }]}
                name={field("type")}
              >
                {viewOrEdit(
                  renderProposalType(data.type),
                  <Select
                    options={[
                      { value: "idea", label: "Idea" },
                      { value: "video", label: "Video" },
                    ]}
                  />
                )}
              </FormColInput>
            )}
            <FormColInput2
              label="Description"
              name={field("description")}
              rules={[{ required: true }]}
            >
              {viewOrEdit(data.description, <TextArea />)}
            </FormColInput2>
            <FormColInput
              label="Proposed budget total"
              name={field("proposed_budget")}
              rules={[{ required: true }]}
            >
              {viewOrEdit(
                data.proposed_budget && renderPrice(data.proposed_budget),
                <Input />
              )}
            </FormColInput>
            {showField("currency") && (
              <FormColInput label="Currency" name={field("currency")}>
                {viewOrEdit(
                  data.currency,
                  <AjaxSelect
                    endpoint={api.currency}
                    fields={(unit) => ({ value: unit.code, label: unit.code })}
                    doGet={false}
                  />
                )}
              </FormColInput>
            )}
            {showField("proposed_work_duration") && (
              <FormColInput
                label="Proposed work duration"
                name={field("proposed_work_duration")}
              >
                {viewOrEdit(
                  data.proposed_work_duration &&
                    data.proposed_work_duration + " Day(-s)",
                  <InputNumber min={0} suffix="Days" />
                )}
              </FormColInput>
            )}
            {data?.submitted_at && (
              <FormColInput label="Submitted at">
                {renderDateTime(data.submitted_at)}
              </FormColInput>
            )}
            {showField("admin_comment") && (
              <FormColInput label="Admin comment" name={field("admin_comment")}>
                {viewOrEdit(data.admin_comment, <Input />)}
              </FormColInput>
            )}
            {id && data?.task && (
              <>
                <Divider>Task details</Divider>
                <FormColInput label="Task" name={field("task")}>
                  <LinkToTask {...data.task} />
                </FormColInput>
                <FormColInput
                  label="Approved by"
                  name={field("approved_by_user")}
                >
                  {data?.approved_by_user?.name}
                </FormColInput>
              </>
            )}
          </MultiColumnForm>
        )}
      </Card>
    </>
  );
};
