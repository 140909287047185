import { useEffect, useState } from "react";
import {
  List,
  Avatar,
  Button,
  Drawer,
  Input,
  Typography,
  Form,
  App,
  Space,
  Popconfirm,
  Badge,
} from "antd";
import {
  CommentOutlined,
  DeleteOutlined,
  EditOutlined,
  MessageOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Comment, PaginatedResponse, ResponseBase, kmAxios } from "api";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { AxiosResponse } from "axios";
import { renderDateTime } from "utils/renderer";

interface CommentComponentProps {
  modelName: string;
  modelId: string;
  comment: Comment;
}

const CommentComponent = ({
  comment,
  modelName,
  modelId,
}: CommentComponentProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isAddingComment, setIsAddingComment] = useState(false);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleAddCommentClick = () => {
    setIsAddingComment(true);
  };
  const { deleteComment } = useDeleteComment(modelName, modelId);

  const handleDeleteClick = () => {
    deleteComment(comment.id);
  };

  const actions: React.ReactNode[] =
    !isEditing && !isAddingComment
      ? [
          <Space key="comment-actions">
            <Button
              size="small"
              key="edit"
              type="text"
              style={{ color: "grey" }}
              onClick={handleEditClick}
              icon={<EditOutlined />}
            />
            <Button
              size="small"
              key="comment"
              type="text"
              onClick={handleAddCommentClick}
              style={{ color: "grey" }}
              icon={<MessageOutlined />}
            />
            {/* <Button
              size="small"
              // onClick={handleSaveClick}
              key="delete"
              icon={<DeleteOutlined />}
            /> */}
            <Popconfirm
              title="Дійсно бажаєте видалити коментар?"
              onConfirm={handleDeleteClick}
              cancelText="Ні"
              okText="Так"
            >
              <Button
                size="small"
                type="text"
                key="delete"
                style={{ color: "grey" }}
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          </Space>,
        ]
      : [];

  return (
    <>
      <List.Item
        style={{
          display: "flex",
          flexDirection: "column",
          marginLeft: comment.parent_id ? "45px" : 0,
          marginBottom: "10px",
        }}
      >
        <List.Item.Meta
          avatar={
            <Avatar src={comment?.user?.avatar_url} icon={<UserOutlined />} />
          }
          title={
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                {comment.user?.name}
                <span
                  style={{
                    paddingLeft: "8px",
                    fontSize: "12px",
                    fontWeight: "normal",
                    color: "grey",
                  }}
                >
                  {renderDateTime(comment.created_at)}
                </span>
              </div>
              <div>{actions}</div>
            </div>
          }
        />
        {isEditing ? (
          <CommentInput
            modelName={modelName}
            modelId={modelId}
            id={comment.id}
            onAfterSave={() => {
              setIsEditing(false);
            }}
            onCancel={() => {
              setIsEditing(false);
            }}
            content={comment.content}
            isEditing={isEditing}
          />
        ) : (
          <Typography style={{ paddingLeft: "45px" }}>
            {comment.content}
          </Typography>
        )}
      </List.Item>
      {isAddingComment && (
        <CommentInput
          modelName={modelName}
          modelId={modelId}
          parent_id={comment.id}
          onAfterSave={() => {
            setIsAddingComment(false);
          }}
          onCancel={() => {
            setIsAddingComment(false);
          }}
          content=""
        />
      )}
    </>
  );
};

interface CommentInputProps extends UseSaveComment {
  modelId: string;
  modelName: string;
  onAfterSave?: () => void;
  onCancel?: () => void;
  isEditing?: boolean;
}

const CommentInput = ({
  modelName,
  modelId,
  id,
  parent_id,
  content: initialContent,
  onAfterSave,
  onCancel,
  isEditing,
}: CommentInputProps) => {
  const [content, setContent] = useState(initialContent);

  const handleCommentChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setContent(event.target.value);
  };

  const { mutate } = useSaveComment(modelName, modelId, onAfterSave);

  const handleSubmit = () => {
    if (content) {
      mutate({
        content,
        id,
        parent_id,
      });
      setContent("");
    }
  };

  return (
    <Form
      style={{
        marginLeft: parent_id ? "45px" : 0,
      }}
    >
      <Form.Item>
        <Input.TextArea
          placeholder="Text..."
          value={content}
          onChange={handleCommentChange}
        />
      </Form.Item>
      <Form.Item>
        <Space>
          <Button onClick={handleSubmit} type="primary">
            {isEditing ? "Save changes" : "Comment"}
          </Button>
          {onCancel && <Button onClick={onCancel}>Cancel</Button>}
        </Space>
      </Form.Item>
    </Form>
  );
};

export interface Props {
  modelName: string;
  modelId?: string;
  // mustWorkWithoutId?: boolean;
  size?: "small" | "middle";
}

export const CommentsComponent: React.FC<
  Props & {
    comments: Comment[];
  }
> = ({ modelName, modelId, comments }) => {
  // if (!modelId && !mustWorkWithoutId) return null;
  if (!modelId) return null;

  return (
    <>
      {comments.length === 0 ? (
        <div
          style={{
            padding: "12px",
            color: "rgba(0, 0, 0, 0.25)",
            fontSize: "13px",
            textAlign: "center",
          }}
        >
          <CommentOutlined style={{ fontSize: "4em" }} />
          <p>Add comment</p>
        </div>
      ) : (
        <List
          itemLayout="vertical"
          dataSource={comments}
          renderItem={(comment) => (
            <CommentComponent
              key={comment.id}
              comment={comment}
              modelName={modelName}
              modelId={modelId}
            />
          )}
        />
      )}
      <CommentInput modelName={modelName} modelId={modelId} content="" />
    </>
  );
};

export const CommentButton: React.FC<Props> = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
  };

  const { data } = useQuery(
    ["comment", props.modelName, props.modelId],
    async (props) => {
      const [_, modelName, modelId] = props.queryKey;
      const { data } = await kmAxios.get<
        ResponseBase<PaginatedResponse<Comment>>
      >(
        `comments/${modelName}/${modelId}`
        // {
        //   params,
        // }
      );
      return data.data;
    },
    {
      enabled: !!props.modelId,
    }
  );
  const comments = data?.items || [];
  const commentsCount = data?.totalItems || undefined;

  if (!props.modelId) return null;

  return (
    <>
      <Badge count={commentsCount} offset={[-4, -4]}>
        <Button
          size={props.size}
          onClick={() => void setIsOpen(true)}
          icon={<CommentOutlined />}
        ></Button>
      </Badge>
      <Drawer
        size="large"
        open={isOpen}
        closable
        onClose={handleClose}
        title="Comments"
        className={"drawer-comment-color"}
      >
        <CommentsComponent {...props} comments={comments} />
      </Drawer>
    </>
  );
};

export interface UseSaveComment {
  id?: string;
  parent_id?: string;
  content: string;
}

const useSaveComment = (
  modelName: string,
  modelId: string,
  onAfterSave?: () => void
) => {
  const queryClient = useQueryClient();
  const { message } = App.useApp();

  const { mutate } = useMutation<ResponseBase<Comment>, any, UseSaveComment>(
    ({ content, parent_id, id }) => {
      const fd = new FormData();
      fd.append("content", content);
      parent_id && fd.append("parent_id", parent_id);
      if (id) {
        fd.append("_method", "PUT");
      }
      return kmAxios
        .post<
          ResponseBase<Comment>,
          AxiosResponse<ResponseBase<Comment>>,
          FormData
        >(`comments/${modelName}/${modelId}/${id || ""}`, fd)
        .then((response) => response.data);
    },
    {
      onError: (e) => {
        message.error(e.response.data.message);
      },
      onSuccess: (response) => {
        onAfterSave?.();
        console.log(response.data);
        queryClient.invalidateQueries(["comment", modelName, modelId]);
      },
    }
  );

  return {
    mutate,
  };
};

const useDeleteComment = (modelName: string, modelId: string) => {
  const queryClient = useQueryClient();
  const { message } = App.useApp();

  const deleteComment = (commentId: string) => {
    return kmAxios
      .delete(`comments/${modelName}/${modelId}/${commentId}`)
      .then((response) => {
        message.success(response.data.message);
        queryClient.invalidateQueries(["comment", modelName, modelId]);
      })
      .catch((error) => {
        message.error(error.data.message);
      });
  };

  return {
    deleteComment,
  };
};
