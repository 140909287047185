import { Card } from "antd";
import { Proposal, api } from "api";
import { DataTable } from "components/DataTable/DataTable";
import { ProposalState } from "components/State/ProposalState";
import { TitleWithActions } from "components/TitleWithActions";
import { useNavigate } from "react-router-dom";
import { renderDateTime, renderPrice } from "utils/renderer";

export const ProposalListPage = () => {
  const navigate = useNavigate();

  const handleEdit = (id: string) => {
    navigate(`/platform/proposals/${id}`);
  };

  return (
    <Card>
      <TitleWithActions title="Proposals" />
      <DataTable<Proposal>
        endpoint={api.proposal}
        columns={columns as any}
        tableId={"ProposalsListPage"}
        onEdit={(record) => {
          handleEdit(record.id);
        }}
      />
    </Card>
  );
};

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    sortIndex: "name",
    sorter: true,
  },
  {
    title: "Author",
    dataIndex: ["author", "name"],
    sortIndex: "author.name",
    key: "author_name",
    sorter: true,
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    sorter: true,
  },
  {
    title: "Proposed budget total",
    dataIndex: "proposed_budget",
    key: "proposed_budget",
    sorter: true,
    render: (val: any) => renderPrice(val),
  },
  {
    title: "Currency",
    dataIndex: "currency",
    key: "currency",
    sorter: true,
  },
  {
    title: "Submitted at",
    dataIndex: "submitted_at",
    key: "submitted_at",
    sorter: true,
    render: renderDateTime,
  },
  {
    title: "Admin comment",
    dataIndex: "admin_comment",
    key: "admin_comment",
    sorter: true,
  },
  {
    title: "Status",
    dataIndex: "state",
    key: "state",
    sorter: true,
    render: ProposalState.render,
  },
];
