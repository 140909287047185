import {
  DashboardOutlined,
  GroupOutlined,
  TeamOutlined,
  BankOutlined,
  CodeSandboxOutlined,
  DollarOutlined,
  UserOutlined,
  FireOutlined,
  ProfileOutlined,
  AuditOutlined,
  ToolOutlined,
  CarOutlined,
  AreaChartOutlined,
  FileTextOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { AuctionHammerIcon } from "components/Icons/AuctionHammerIcon";
import { Menu, MenuProps } from "antd";
import { Link, useLocation } from "react-router-dom";
import { useAuthContext } from "AuthProvider";

export interface MenuItem {
  label: React.ReactNode;
  key: string;
  icon?: React.ReactNode;
  children?: MenuItem[];
  type?: "group";
  url?: string;
  title: string;
}

function getItem(
  label: string,
  key: string,
  url?: string,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return {
    key,
    icon,
    children,
    url: url,
    label: url ? <Link to={url}>{label}</Link> : label,
    title: label,
    type,
  };
}

const userItems: MenuItem[] = [
  // getItem("Dashboard", "platform", "/platform", <DashboardOutlined />),
  // getItem("Profile", "profile", "/profile", <UserOutlined />),

  getItem(
    "Current Auctions",
    "current-auction",
    "/platform/current-auctions",
    <AuctionHammerIcon />
  ),
  getItem(
    "Closed Auctions",
    "closed-auction",
    "/platform/closed-auctions",
    <AuctionHammerIcon />
  ),

  getItem(
    "My Proposals",
    "my-proposals",
    "/platform/my-proposals",
    <FireOutlined />
  ),

  getItem("Tasks", "Tasks", "/platform/tasks", <ProfileOutlined />),
  // getItem("Contracts", "Contracts", "/platform/contracts", <AuditOutlined />),
  // getItem("Payments", "Payments", "/platform/payments", <DollarOutlined />),
];

const adminItems: MenuItem[] = [
  getItem("Dashboard", "platform", "/platform", <DashboardOutlined />),
  // getItem("Profile", "profile", "/profile", <UserOutlined />),

  getItem("Auctions", "auction", "/platform/auctions", <AuctionHammerIcon />),

  getItem("Tasks", "Tasks", "/platform/tasks", <ProfileOutlined />),
  getItem("Contracts", "Contracts", "/platform/contracts", <AuditOutlined />),
  getItem("Payments", "Payments", "/platform/payments", <DollarOutlined />),
  getItem("Proposals", "Proposals", "/platform/proposals", <FireOutlined />),
  getItem("Admins", "admins", "/platform/admins", <TeamOutlined />),
  getItem("Users", "users", "/platform/users", <TeamOutlined />),
  getItem("Pages", "pages", "/platform/pages", <FileTextOutlined />),
  getItem(
    "Configuration",
    "configuration",
    undefined,
    <SettingOutlined />,
    [
      getItem("Currencies", "Currencies", "/platform/currencies"),
      getItem("Categories", "Categories", "/platform/categories"),
      getItem("Subcategories", "Subcategories", "/platform/subcategories"),
      getItem("Skills", "Skills", "/platform/skills"),
      getItem("Countries", "Countries", "/platform/countries"),
    ].filter((item) => item !== null) as MenuItem[]
  ),
];

export const LeftNavigation: React.FC = () => {
  const location = useLocation();
  const { hasAdminRole, hasUserRole } = useAuthContext();

  const getItems = () => {
    if (hasAdminRole) {
      return adminItems;
    } else if (hasUserRole) {
      return userItems;
    } else {
      return [];
    }
  };
  const items = getItems();

  const getSelectedKeys = (menuItem: MenuItem[]): string[] => {
    const items = menuItem;
    const keys = items
      .filter((item) => item.url === location.pathname)
      .map((item) => item.key);
    const childKeys = items
      .filter((item) => item.children)
      .map((item) => getSelectedKeys(item.children!))
      .reduce((result, keys) => [...result, ...keys], []);
    return [...keys, ...childKeys];
  };

  const selectedKeys = getSelectedKeys(items);

  return (
    <Menu
      style={{ position: "sticky", top: 0, height: "100vh", overflowY: "auto" }}
      selectedKeys={selectedKeys}
      theme="light"
      mode="inline"
      items={items as any}
    />
  );
};

// mode="inline"
//           defaultSelectedKeys={["1"]}
//           defaultOpenKeys={["sub1"]}
//           style={{ height: "100%", borderRight: 0 }}
//           items={leftMenuItems}
