import { api } from "api";
import {
  CheckOutlined,
  FileTextOutlined,
  ClockCircleOutlined,
  LikeOutlined,
  StopOutlined,
  LaptopOutlined,
  ExclamationCircleOutlined,
  DollarOutlined,
} from "@ant-design/icons";
import { BeforeStateChange, GenerateStatuses } from "./stateStepUtils";
import Modal from "antd/es/modal/Modal";
import { useState } from "react";
import { Form } from "antd";
import TextArea from "antd/es/input/TextArea";

const iconStyle = { paddingRight: "6px" };

export const ProposalState = GenerateStatuses({
  endpoint: api.proposal,
  options: [
    {
      value: "draft",
      label: "Draft",
      icon: <FileTextOutlined style={{ ...iconStyle, color: "grey" }} />,
    },
    {
      value: "changes_requested",
      label: "Changes Requested",
      icon: (
        <ExclamationCircleOutlined style={{ ...iconStyle, color: "orange" }} />
      ),
      beforeChange: ({ state, sendData }) => (
        <RequestChangesModal
          key={Date.now()}
          state={state}
          sendData={sendData}
        />
      ),
    },
    {
      value: "submitted",
      label: "Submitted",
      icon: <ClockCircleOutlined style={{ ...iconStyle, color: "orange" }} />,
      availableActions: ["changes_requested", "rejected", "good_idea"],
    },
    {
      value: "price_negotiation",
      label: "Price negotiation",
      icon: <DollarOutlined style={{ ...iconStyle, color: "orange" }} />,
      availableActions: ["good_idea", "rejected", "production_started"],
    },
    {
      value: "good_idea",
      label: "Good idea",
      icon: <LikeOutlined style={{ ...iconStyle, color: "blue" }} />,
      availableActions: ["production_started", "rejected", "price_negotiation"],
    },

    {
      value: "production_started",
      label: "Production started",
      icon: <LaptopOutlined style={{ ...iconStyle, color: "blue" }} />,
    },
    {
      value: "rejected",
      label: "Rejected",
      icon: <StopOutlined style={{ ...iconStyle, color: "red" }} />,
    },
    {
      value: "completed",
      label: "Completed",
      icon: <CheckOutlined style={{ ...iconStyle, color: "green" }} />,
    },
  ],
});

const RequestChangesModal = ({ state, sendData }: BeforeStateChange) => {
  const [open, setOpen] = useState(true);
  const [form] = Form.useForm();

  const handleFinish = async (data: any) => {
    const fd = new FormData();
    fd.append("state", state);
    fd.append("admin_comment", data.admin_comment);
    try {
      await sendData(fd);
      setOpen(false);
    } catch (e) {}
  };

  return (
    <Modal
      onOk={() => {
        form.submit();
      }}
      okText="Ok"
      open={open}
      onCancel={() => setOpen(false)}
      title="Request changes"
    >
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        layout="horizontal"
        style={{ maxWidth: 852 }}
        initialValues={{}}
        form={form}
        onFinish={handleFinish}
        autoComplete="off"
        labelWrap
      >
        <Form.Item
          label="Comment"
          name="admin_comment"
          rules={[{ required: true }]}
        >
          <TextArea rows={3} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
