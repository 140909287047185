import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

interface PhoneInputProps {
  value?: string;
  hideFlag?: boolean;
  onChange?: (value: string) => void;
}

export const PhoneInputEdit: React.FC<PhoneInputProps> = ({
  value,
  onChange,
}) => {
  return (
    <PhoneInput
      country="us"
      // regions={"europe"}
      excludeCountries={["ru", "by"]}
      value={value}
      placeholder={"+1 234 56 78"}
      onChange={onChange}
      inputStyle={{
        color: "rgba(0, 0, 0, 0.88)",
        borderWidth: "1px",
        borderStyle: "solid",
        width: "100%",
        // borderColor: "#eff0f0",
        borderRadius: "6px",
        transition: "all 0.2s",
      }}
      buttonStyle={{
        color: "rgba(0, 0, 0, 0.88)",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "transparent",
        borderRadius: "6px",
        transition: "all 0.2s",
        backgroundColor: "transparent",
      }}
    />
  );
};
export const PhoneInputView: React.FC<PhoneInputProps> = ({
  value,
  hideFlag,
}) => {
  if (!value) return <></>;
  return (
    <PhoneInput
      // country="us"
      value={value}
      excludeCountries={["ru", "by"]}
      disabled
      inputStyle={{
        color: "rgba(0, 0, 0, 0.88)",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "#eff0f0",
        borderRadius: "6px",
        border: "none",
        cursor: "default",
      }}
      buttonStyle={{
        color: "rgba(0, 0, 0, 0.88)",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "transparent",
        borderRadius: "6px",
        transition: "all 0.2s",
        backgroundColor: "transparent",
        padding: "0",
      }}
      // containerClass="view"
      inputClass="view"
      containerClass={hideFlag ? "hide-flag" : ""}
      buttonClass="view"
      dropdownClass="view"
      searchClass="view"
    />
  );
};
