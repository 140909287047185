import axios, { AxiosError, AxiosResponse } from "axios";

import {
  Auction,
  Bid,
  Contract,
  Country,
  CrudOperations, Currency, Order, Page, Payment, Proposal, Section, Stats, Subcategory, Task, User,
  UserSkill,

} from "./types";

import { REACT_APP_API } from "../env";
import { ResponseBase, PaginatedResponse, ListFilters } from "./types";

export const kmAxios = axios.create({
  baseURL: REACT_APP_API,
  headers: {
    "Access-Control-Allow-Credentials": true,
    "Access-Control-Allow-Origin": "*",
  },
  withCredentials: false,
});

export type HttpErrorType = AxiosError;

const listOperation =
  <T>(url: string) =>
  async (params?: ListFilters): Promise<PaginatedResponse<T>> => {
    const { data } = await kmAxios.get<ResponseBase<PaginatedResponse<T>>>(
      url,
      {
        params,
      }
    );
    return data.data;
  };
const getOperation =
  <T>(url: string) =>
  async (id: string): Promise<PaginatedResponse<T>> => {
    const { data } = await kmAxios.get<ResponseBase<PaginatedResponse<T>>>(
      `${url}/${id}`
    );
    return data.data;
  };

const crudOperations = <T, P = any, B = FormData | P | undefined>(
  url: string,
  key: string
): CrudOperations<T, P, B> => ({
  key,
  list: async (params) => {
    const { data } = await kmAxios.get<ResponseBase<PaginatedResponse<T>>>(
      url,
      {
        params,
      }
    );
    return data.data;
  },
  ajaxList: async (params: any) => {
    const { data } = await kmAxios.get<ResponseBase<PaginatedResponse<T>>>(
      url,
      {
        headers: {
          'Ajax-Select': process.env.REACT_APP_AJAX_SELECT_SECRET
        },
        params,
      }
    );
    return data.data;
  },
  get: async (id) => {
    const { data } = await kmAxios.get<ResponseBase<T>>(`${url}/${id}`);
    return data;
  },
  ajaxGet: async (id) => {
    const { data } = await kmAxios.get<ResponseBase<T>>(`${url}/${id}`, {
      headers: {
        'Ajax-Select': process.env.REACT_APP_AJAX_SELECT_SECRET
      }
    });
    return data;
  },
  update: (id, data) => {
    if (data instanceof FormData) {
      data.append("_method", "PUT");
    } else {
      // @ts-ignore
      data._method = "PUT";
    }
    return kmAxios
      .post<ResponseBase<T>, AxiosResponse<ResponseBase<T>>, B>(
        `${url}/${id}`,
        data
      ) // !!!
      .then((response: any) => response.data);
  },
  create: (data) => {
    // if (data instanceof FormData) {
    //   data.append('_method', 'PUT');
    // }
    return kmAxios
      .post<ResponseBase<T>, AxiosResponse<ResponseBase<T>>, B>(url, data)
      .then((response: any) => response.data);
  },
  delete: (id) => {
    return kmAxios
      .delete<ResponseBase<T>>(`${url}/${id}`)
      .then((response: any) => response.data);
  },
  preview: async (params) => {
    const { data } = await kmAxios.get<ResponseBase<T>>(`${url}/create`, {
      params: params,
    });
    return data;
  },
  previewEdit: async (id, params) => {
    const { data } = await kmAxios.get<ResponseBase<T>>(`${url}/${id}/edit`, {
      params: params,
    });
    return data;
  },
});

export const api = {
  order: crudOperations<Order>("/orders", "Order"),
  car: crudOperations<Order>("/cars", "Cars"),
  user: {
    ...crudOperations<User>("/users", "User"),
    updateUserAvatar: (userId: string, data: FormData) => {
      return kmAxios.post(`/users/${userId}/update-avatar`, data).then(r => r.data);
    },
    register: (email: string, password: string, c_password: string) => {
      return kmAxios.post(`/register`, {
        email,
        password,
        c_password,
      }).then(r => r.data);
    },
    resetPassword: (userId: string) => {
      return kmAxios.post(`/users/${userId}/reset-password`).then(r => r.data);
    },
  },
  page: crudOperations<Page>("/pages", "Page"),
  category: crudOperations<Section>("/sections", "Section"),
  subcategory: crudOperations<Subcategory>("/subcategories", "Subcategory"),
  auction: crudOperations<Auction>("/auctions", "Auction"),
  currency: crudOperations<Currency>("/currencies", "Currency"),
  country: crudOperations<Country>("/countries", "Country"),
  contract: crudOperations<Contract>("/contracts", "Contract"),
  payment: crudOperations<Payment>("/payments", "Payment"),
  task: crudOperations<Task>("/tasks", "Task"),
  taskFile: crudOperations<Task>("/task-files", "TaskFile"),
  bid: crudOperations<Bid>("/bids", "Bid"),
  skill: crudOperations<Section>("/skills", "Skill"),
  userSkill: crudOperations<UserSkill>("/user-skills", "UserSkill"),
  mySkill: crudOperations<UserSkill>("/my-skills", "MySkill"),
  proposal: crudOperations<Proposal>("/proposals", "Proposal"),
  myProposal: crudOperations<Proposal>("/my-proposals", "Proposal"),
  meStats: {
    get: () => {
      return kmAxios.get<ResponseBase<Stats>>(`/me/stats`).then(r => r.data);
    },
  },
  me: {
    key: 'me',
    get: () => {
      return kmAxios.get<ResponseBase<User>>(`/me`).then(r => r.data);
    },
    update: (id: string, data: FormData) => {
      return kmAxios.post(`/me`, data).then(r => r.data);
    },
    list: async (params: any) => {
      throw new Error("List method not supported.");
    },
    create: async (params: any) => {
      throw new Error("Create method not supported.");
    },
    delete: async (params: any) => {
      throw new Error("Delete method not supported.");
    },
    ajaxList: async (params: any) => {
      throw new Error("AjaxList method not supported.");
    },
    ajaxGet: async (params: any) => {
      throw new Error("AjaxGet method not supported.");
    },
    generateBarcode: (userId: string) => {
      return kmAxios.post(`/me/regenerate-barcode`).then(r => r.data);
    },
    updateUserAvatar: (userId: string, data: FormData) => {
      return kmAxios.post(`/me/update-avatar`, data).then(r => r.data);
    },
    updateMyPassword: (data: FormData) => {
      return kmAxios.post(`/me/update-password`, data).then(r => r.data);
    }
  },
};
