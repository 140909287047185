import { Ref, forwardRef, useEffect, useRef, useState } from "react";
import { App, Button, Divider, Select, Space } from "antd";
import { BaseModel, CrudOperations, ResponseBase } from "api";
import { BaseOptionType, RefSelectProps } from "antd/es/select";
import { PlusOutlined } from "@ant-design/icons";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useFormData } from "hooks/useFormData";

export interface AjaxSelectProps<T> {
  style?: React.CSSProperties;
  disabled?: boolean;
  endpoint: CrudOperations<T>;
  value?: string;
  placeholder?: string;
  fields?: (entity: T) => BaseOptionType;
  filter?: {};
  popupMatchSelectWidth?: boolean;
  onChange?: (value: any, option?: T) => void;
  doGet?: boolean;
  onCreate?: () => void;
  onCreateAndSelectProps?: any;
  onCreateLink?: string;
  onAfterGet?: (data?: T) => void;
  bordered?: boolean;
  optionRender?: any;
}

const AjaxSelectComponent = forwardRef(
  <T extends BaseModel>(
    {
      style,
      value: initialValue,
      onChange,
      endpoint,
      fields = (entity: any) => ({ value: entity.id, label: entity?.name }),
      placeholder,
      filter = {},
      disabled = false,
      popupMatchSelectWidth = false,
      doGet = true,
      onCreate,
      onCreateAndSelectProps,
      onAfterGet,
      onCreateLink,
      bordered,
      optionRender,
    }: AjaxSelectProps<T>,
    ref: Ref<RefSelectProps>
  ) => {
    const { message } = App.useApp();
    const { collect } = useFormData();
    const queryClient = useQueryClient();

    const [focused, setFocused] = useState<boolean>(false);
    const [selectedOption, setSelectedOption] = useState<T | undefined>(
      undefined
    );
    // const [searchOptions, setSearchOptions] = useState<T[]>([]);
    const [value, setValue] = useState<string>();

    const [search, setSearch] = useState<string>("");

    const { data: options } = useQuery(
      [endpoint.key, search],
      async () => {
        const { items } = await endpoint.ajaxList({ search, ...filter });
        return items;
      },
      {
        enabled: focused,
      }
    );
    const searchOptions = options || [];

    useEffect(() => {
      if (initialValue) {
        if (doGet) {
          endpoint.ajaxGet(initialValue).then((resp) => {
            setSelectedOption(resp.data);
            setValue(initialValue);
            onAfterGet?.(resp.data);
          });
        } else {
          setValue(initialValue);
        }
      } else {
        setValue(undefined);
        onAfterGet?.(undefined);
      }
    }, [initialValue]);

    const { mutate: createNewOption } = useMutation<ResponseBase<T>, any, any>(
      ({ data }) => {
        return endpoint.create(data);
      },
      {
        onError: (e) => {
          message.error(e.response.data.message);
        },
        onSuccess: (response, variables) => {
          setValue(response.data.id);
          setSelectedOption(response.data);
          onChange?.(response.data.id, response.data);
          queryClient.invalidateQueries(endpoint.key);
          message.success(response.message);
          setFocused(false);
        },
      }
    );

    const handleChange = (value: any) => {
      setValue(value);
      const option = searchOptions.find((o) => o.id === value);
      onChange?.(value, option);
      onAfterGet?.(option);
    };

    const selectOptions = focused
      ? searchOptions.map(fields)
      : selectedOption
      ? [selectedOption].map(fields)
      : [];

    const transformValue = (value: any) => {
      // return value ? Number(value) : value;
      // console.log("!!! value", value);
      if (value === undefined) {
        return value;
      }
      const number = Number(value);
      if (isNaN(number)) {
        return value;
      }
      return number;
    };

    return (
      <Select
        style={{ minWidth: "120px", ...style }}
        ref={ref}
        popupMatchSelectWidth={popupMatchSelectWidth}
        disabled={disabled}
        showSearch
        allowClear={true}
        placeholder={placeholder}
        value={transformValue(value)}
        open={focused}
        onSearch={(search: string) => {
          setSearch(search);
        }}
        onDropdownVisibleChange={(open) => {
          setFocused(open);
          if (open) {
            setSearch("");
          }
        }}
        onChange={handleChange}
        filterOption={false}
        options={selectOptions}
        optionRender={optionRender}
        bordered={bordered}
        dropdownRender={(menu) => (
          <>
            {menu}
            {onCreate && (
              <>
                <Divider style={{ margin: "8px 0" }} />
                <Space
                  style={{
                    padding: "0 8px 8px",
                    width: "calc(100% - 16px)",
                    justifyContent: "right",
                  }}
                >
                  <Button
                    type="text"
                    icon={<PlusOutlined />}
                    onClick={(e) => {
                      onCreate();
                    }}
                  >
                    Створити
                  </Button>
                </Space>
              </>
            )}
            {onCreateAndSelectProps && (
              <>
                <Divider style={{ margin: "8px 0" }} />
                <Space
                  style={{
                    padding: "0 8px 8px",
                    width: "calc(100% - 16px)",
                    justifyContent: "right",
                  }}
                >
                  <Button
                    type="text"
                    icon={<PlusOutlined />}
                    onClick={(e) => {
                      const fd = collect(onCreateAndSelectProps);
                      createNewOption({
                        data: fd,
                      });
                    }}
                  >
                    Створити
                  </Button>
                </Space>
              </>
            )}
            {onCreateLink && (
              <>
                <Divider style={{ margin: "8px 0" }} />
                <Space
                  style={{
                    padding: "0 8px 8px",
                    width: "calc(100% - 16px)",

                    justifyContent: "right",
                  }}
                >
                  <Button
                    type="text"
                    icon={<PlusOutlined />}
                    onClick={() => {
                      window.open(onCreateLink, "_blank");
                    }}
                  >
                    Створити
                  </Button>
                </Space>
              </>
            )}
          </>
        )}
      >
        {/* {options.map((option: any) => (
        <Option key={option.id} value={option.id}>
          {option.plate}
        </Option>
      ))} */}
      </Select>
    );
  }
);

export const AjaxSelect = AjaxSelectComponent as <T extends unknown>(
  props: AjaxSelectProps<T> & { ref?: Ref<RefSelectProps> }
) => JSX.Element;
