import {
  Card,
  Divider,
  Input,
  InputNumber,
  Select,
  Tabs,
  Statistic,
  TabsProps,
} from "antd";
import { useWatch } from "antd/es/form/Form";
import { Auction, api } from "api";
import { TitleWithActions } from "components/TitleWithActions";
import { useLPForm } from "hooks/useLPForm";
import { Link, useParams } from "react-router-dom";
import { Breadcrumbs } from "components/Breadcrumbs";
import { AjaxSelect } from "components/AjaxSelect";
import dayjs from "dayjs";
import TextArea from "antd/es/input/TextArea";
import { LpDateTimePicker } from "components/LpDateTimePicker";
import { AuctionTabTaskWrapper } from "./AuctionTabTask";
import { AuctionState } from "components/State/AuctionState";
import { renderDate, renderPrice } from "utils/renderer";
import { AuctionTabBids } from "./AuctionTabBids";
import { useAuthContext } from "AuthProvider";
import { FormColInput, MultiColumnForm } from "components/MultiColumnForm";
import { Fragment } from "react";

const { Countdown } = Statistic;

export const AuctionEditPage = () => {
  const { auctionId: id } = useParams<{ auctionId: string }>();
  const { user, hasAdminRole, hasUserRole } = useAuthContext();

  const {
    data,
    showForm,
    form,
    save,
    field,
    isEditMode,
    viewOrEdit,
    actions,
    toFormData,
  } = useLPForm<Auction>({
    id,
    isEdit: !id,
    initialData: { currency: "USD" },
    endpoint: api.auction,
    onAfterCreateNavigateTo: (data) => `/platform/auctions/${data.id}`,
    onAfterDeleteNavigateTo: (data) => "/platform/auction",
  });

  const closingType = useWatch("closing_type", form);

  if (!showForm) return null;

  const canSeeTask = hasAdminRole || data?.winner_id === user?.id;

  const showField = (field: string) =>
    isEditMode ? true : (data as any)[field];

  const tabs: TabsProps["items"] = [];

  if (canSeeTask) {
    tabs.push({
      key: "task",
      label: `Task`,
      children: data?.id ? (
        <AuctionTabTaskWrapper auctionId={data?.id} />
      ) : null,
      disabled: !data?.id,
    });
  }

  tabs.push({
    key: "bids",
    label: `Bids`,
    children: data?.id ? <AuctionTabBids auctionId={data?.id} /> : null,
    disabled: !data?.id || !["closed", "active"].includes(data?.state),
  });

  const showCurrency =
    hasAdminRole || ["draft", "scheduled"].includes(data?.state);

  return (
    <>
      <Breadcrumbs
        items={[
          {
            title: "Auctions",
            to: "/platform/auctions",
          },
          {
            title: "Auction",
          },
        ]}
      />
      <Card style={{ border: "none", backgroundColor: "white" }}>
        {data?.state === "scheduled" && (
          <Countdown
            title="Time to auction start"
            value={data.start_date}
            format="D [days] HH:mm:ss"
            onFinish={() => {}}
          />
        )}
        {data?.state === "active" && data.closing_type === "auto" && (
          <Countdown
            title="Time to auction end"
            value={data.end_date}
            format="D [days] HH:mm:ss"
            onFinish={() => {}}
          />
        )}
        <TitleWithActions
          title={id ? data?.name : "Create auction"}
          actions={
            hasAdminRole && (
              <>
                {id && <AuctionState.Selector id={id} state={data?.state} />}
                {data?.state !== "closed" && actions}
              </>
            )
          }
        />
        {showForm && (
          <MultiColumnForm
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            layout="horizontal"
            style={{ maxWidth: 1200 }}
            initialValues={{
              // @ts-ignore
              type: "dutch",
              // @ts-ignore
              closing_type: "manual",
              // @ts-ignore
              max_size: 5000,
              ...data,
            }}
            form={form}
            labelWrap={true}
            onFinish={(data) => {
              save(toFormData(data));
            }}
            autoComplete="off"
          >
            <FormColInput
              label="Name"
              name={field("name")}
              rules={[{ required: true }]}
            >
              {viewOrEdit(data.name, <Input />)}
            </FormColInput>

            {id && hasAdminRole && (
              <FormColInput label="Status">
                <>{AuctionState.render(data?.state)}</>
              </FormColInput>
            )}
            {hasAdminRole && (
              <FormColInput label="Type" name={field("type")}>
                {viewOrEdit(
                  getAuctionTypeDisplayName(data.type),
                  <Select
                    options={[
                      {
                        value: "dutch",
                        label: "Dutch",
                      },
                      {
                        value: "english",
                        label: "English",
                      },
                    ]}
                  />
                )}
              </FormColInput>
            )}
            {hasAdminRole && (
              <FormColInput label="Closing type" name={field("closing_type")}>
                {viewOrEdit(
                  getClosingTypeDisplayName(data.closing_type),
                  <Select
                    options={[
                      {
                        value: "manual",
                        label: "Manual",
                      },
                      {
                        value: "auto",
                        label: "Auto",
                      },
                    ]}
                  />
                )}
              </FormColInput>
            )}
            {hasAdminRole && (
              <FormColInput
                label="Start date"
                name={field("start_date")}
                rules={[{ required: true }]}
              >
                {viewOrEdit(
                  data.start_date,
                  <LpDateTimePicker
                    disabledDate={(current) =>
                      current && current < dayjs().startOf("day")
                    }
                  />
                )}
              </FormColInput>
            )}
            {showField("end_date") && (
              <FormColInput
                label="End date"
                name={field("end_date")}
                rules={
                  closingType === "auto" ? [{ required: true }] : undefined
                }
              >
                {viewOrEdit(
                  data.end_date,
                  <LpDateTimePicker
                    disabledDate={(current) =>
                      current && current < dayjs().startOf("day")
                    }
                  />
                )}
              </FormColInput>
            )}
            {showField("deadline_date") && (
              <FormColInput
                label="Commitment deadline"
                name={field("deadline_date")}
                rules={[{ required: true }]}
              >
                {viewOrEdit(
                  renderDate(data.deadline_date),
                  // todo: add LPDatePicker
                  <LpDateTimePicker
                    disabledDate={(current) =>
                      current && current < dayjs().startOf("day")
                    }
                  />
                )}
              </FormColInput>
            )}
            <FormColInput
              label="Category"
              name={field("section_id")}
              rules={[{ required: true }]}
            >
              {viewOrEdit(
                data.section?.name,
                // todo: make dependable
                <AjaxSelect endpoint={api.category} />
              )}
            </FormColInput>
            {showField("subcategory_id") && (
              <FormColInput label="Subcategory" name={field("subcategory_id")}>
                {viewOrEdit(
                  data.subcategory?.name,
                  // todo: make dependable
                  <AjaxSelect endpoint={api.subcategory} />
                )}
              </FormColInput>
            )}
            {showField("price") && (
              <FormColInput label="Price" name={field("price")}>
                {viewOrEdit(data.price && renderPrice(data.price), <Input />)}
              </FormColInput>
            )}
            {showCurrency && showField("currency") && (
              <FormColInput label="Currency" name={field("currency")}>
                {viewOrEdit(
                  data.currency,
                  <AjaxSelect
                    endpoint={api.currency}
                    fields={(unit) => ({ value: unit.code, label: unit.code })}
                    doGet={false}
                  />
                )}
              </FormColInput>
            )}
            <FormColInput
              label="Video quantity"
              name={field("quantity")}
              rules={[{ required: true }]}
            >
              {viewOrEdit(data.quantity, <InputNumber min={0} />)}
            </FormColInput>
            <FormColInput
              label="Description"
              name={field("description")}
              rules={[{ required: true }]}
            >
              {viewOrEdit(
                renderTextWithLinks(data.description),
                <TextArea rows={4} />
              )}
            </FormColInput>
            <FormColInput
              label="Max size per 1 file"
              name={field("max_size")}
              rules={[{ required: true }]}
            >
              {viewOrEdit(
                data.max_size && data.max_size + " Megabytes",
                <InputNumber min={0} addonAfter="Megabytes" />
              )}
            </FormColInput>
            {data?.state !== "draft" && data?.participants_count && (
              <>
                <Divider>Bidding stats</Divider>
                <FormColInput label="Participants">
                  {data?.participants_count}
                </FormColInput>
                <FormColInput label="Max Bid">
                  {data?.stats?.max_bid &&
                    renderPrice(data?.stats?.max_bid, data?.currency)}
                </FormColInput>
                <FormColInput label="Min Bid">
                  {data?.stats?.min_bid &&
                    renderPrice(data?.stats?.min_bid, data?.currency)}
                </FormColInput>
              </>
            )}
          </MultiColumnForm>
        )}
        {id && <Tabs items={tabs} defaultActiveKey={undefined} />}
      </Card>
    </>
  );
};

export const renderTextWithLinks = (text?: string): JSX.Element => {
  if (!text) return <></>;
  // Splitting the text by new lines to preserve line breaks
  const lines = text.split("\n");
  // Regular expression to detect URLs
  const urlRegex = /\bhttps?:\/\/[^\s]+\b/g;

  return (
    <span>
      {lines.map((line, lineIndex) => (
        <Fragment key={`line-${lineIndex}`}>
          {line.split(" ").map((word, wordIndex) => {
            // If the word is a URL, render it as a link
            if (urlRegex.test(word)) {
              return (
                <Fragment key={`word-${lineIndex}-${wordIndex}`}>
                  <Link to={word} target="_blank">
                    {word}
                  </Link>{" "}
                </Fragment>
              );
            }
            // If the word is not a URL, just render it with a following space
            return (
              <Fragment key={`word-${lineIndex}-${wordIndex}`}>
                {word}{" "}
              </Fragment>
            );
          })}
          {/* Adding a line break if it's not the last line */}
          {lineIndex < lines.length - 1 && <br />}
        </Fragment>
      ))}
    </span>
  );
};

const auctionTypesMap: { [key: string]: string } = {
  dutch: "Dutch",
  english: "English",
};
export const getAuctionTypeDisplayName = (type: string) =>
  auctionTypesMap[type] || type;

const closingTypesMap: { [key: string]: string } = {
  manual: "Manual",
  auto: "Auto",
};
export const getClosingTypeDisplayName = (type: string) =>
  closingTypesMap[type] || type;
